[data-v-0dbbdcf2] .el-tabs__header {
  padding-left: 12px;
  margin-bottom: 8px;
}
[data-v-0dbbdcf2] .el-tabs__content {
  padding: 0 !important;
}
[data-v-0dbbdcf2] .bjs-powered-by {
  display: none;
}
[data-v-0dbbdcf2] .upload-tab .el-tabs__nav-scroll {
  padding-top: 0 !important;
  width: 100% !important;
}