.head-search[data-v-1d892be0] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 6px 12px;
  border: 1px solid #ccc;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  font-size: 14px;
  background: #fff;
}
.head-search span[data-v-1d892be0] {
  width: 200px;
}
.main-content[data-v-1d892be0] {
  height: calc(100vh - 170px);
  background: #fff;
  padding-top: 4px;
}
.main-content[data-v-1d892be0] .el-tabs {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
.main-content[data-v-1d892be0] .el-tabs__header {
  height: calc(100% - 1px) !important;
}
.main-content[data-v-1d892be0] .el-tabs--border-card {
  background-color: #F5F7FA;
}
.main-content[data-v-1d892be0] .el-tabs__content {
  padding: 0;
}
.main-content[data-v-1d892be0] .el-tabs--left .el-tabs__header.is-left {
  margin-right: 0;
}
.main-content[data-v-1d892be0] .el-tabs--left.el-tabs--border-card .el-tabs__item.is-left {
  margin: 0 10px;
  border: none !important;
}
.main-content[data-v-1d892be0] .el-tabs__nav-scroll {
  background: #fff;
  padding-top: 15px;
  margin-right: 10px;
  width: 180px;
}
.main-content[data-v-1d892be0] .el-tabs--left.el-tabs--border-card .el-tabs__item.is-left.is-active {
  border: none;
  background: #e2e5ea;
  border-radius: 6px;
}
.main-content[data-v-1d892be0] .el-tabs--left .el-tabs__item.is-left {
  text-align: left;
}
.main-content[data-v-1d892be0] .el-tabs__item .iconfont {
  font-size: 18px;
  margin-right: 10px;
}